import { useRef, useEffect, useState, FC } from "react";
import type { ChartData } from "chart.js";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./LineChart.scss";
import { getChartWithGradientColor } from "../Helpers/ChartUtility";

ChartJS.register(...registerables, ChartDataLabels);

interface ILineChartProps {
  chartData: any;
  chartOptions: any;
  colorStartFrom?: string;
}

const LineChart: FC<ILineChartProps> = (props: ILineChartProps) => {
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"line">>({ datasets: [] });

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const data = getChartWithGradientColor(props.chartData, chartRef.current, props.colorStartFrom);
    setChartData(data);
  }, []);

  return (
    <div className="" data-testid="LineChart">
      <Chart ref={chartRef} type="line" data={chartData} options={props.chartOptions} height={"101%"} />
    </div>
  );
};

export default LineChart;
