export const BurgerMenuIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 23 35">
            <g id="menu" transform="translate(-5.625 -6.75)">
                <path id="Path_10695" data-name="Path 10695" d="M5.625,6.75h24V9h-24Z" fill="#fff" />
                <path id="Path_10696" data-name="Path 10696" d="M5.625,16.875h24v2.25h-24Z" transform="translate(0 -2.25)" fill="#fff" />
                <path id="Path_10697" data-name="Path 10697" d="M5.625,27h24v2.25h-24Z" transform="translate(0 -4.5)" fill="#fff" />
            </g>
        </svg>
    )
}