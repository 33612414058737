export function authToken() {
  return window.localStorage.getItem("access_token");
}

export function idToken() {
  return window.localStorage.getItem("id_token");
}

export function setAuthDetails(accessToken, idTokenDetails) {
  window.localStorage.setItem("access_token", accessToken);
  window.localStorage.setItem("id_token", idTokenDetails);
}
