type TwitterTrendStatusIconProps = {
    color: string;
}

export const TwitterTrendStatusIcon: React.FC<TwitterTrendStatusIconProps> = ({ color }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="9" viewBox="0 0 27 9">
            <g id="Group_738" data-name="Group 738" transform="translate(-1743.5 -792)">
                <line id="Line_100" data-name="Line 100" x1="27" transform="translate(1743.5 796.5)" fill="none" stroke={color} stroke-width="3" />
                <circle id="Ellipse_30" data-name="Ellipse 30" cx="4.5" cy="4.5" r="4.5" transform="translate(1753 792)" fill={color} />
            </g>
        </svg>
    )
};