import { PrintMediaIcon } from "../icons/PrintMediaIcon";
import { SocialMediaIcon } from "../icons/SocialMediaIcon";
import { TvChannelsIcon } from "../icons/TvChannelsIcon";

export const PrintMediaRoutePath = "/PrintMedia";
export const TvChannelsRoutePath = "/TvChannels";
export const SocialMediaRoutePath = "/SocialMedia";

export const PrintMediaBgImage = "bg-printMedia";
export const TvChannelsBgImage = "bg-tvChannels";
export const SocialMediaBgImage = "bg-socialMedia";

export const PrintMediaTitle = "Print Media";
export const TvChannelsTitle = "Tv Channels";
export const SocialMediaTitle = "Social Media";

export const PrintMediaTitleStyle = "text-printTitle";
export const TvChannelsTitleStyle = "text-tvTitle";
export const SocialMediaTitleStyle = "text-socialTitle";

export const AppMenus = [
  {
    icon: <PrintMediaIcon />,
    title: PrintMediaTitle,
    routingPath: PrintMediaRoutePath,
    bgImage: PrintMediaBgImage,
    titleClass: PrintMediaTitleStyle,
  },
  {
    icon: <TvChannelsIcon />,
    title: TvChannelsTitle,
    routingPath: TvChannelsRoutePath,
    bgImage: TvChannelsBgImage,
    titleClass: TvChannelsTitleStyle,
  },
  {
    icon: <SocialMediaIcon />,
    title: SocialMediaTitle,
    routingPath: SocialMediaRoutePath,
    bgImage: SocialMediaBgImage,
    titleClass: SocialMediaTitleStyle,
  },
];
