import "./SignIn.scss";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { FC } from "react";
import { loginRequest } from "../../api/msal.service";
import { InteractionType } from "@azure/msal-browser";

interface SignInProps {}

function LoadingComponent() {
  return (
    <Container sx={{ textAlign: "center" }}>
      <p>Media Monitoring</p>
      <div style={{ marginBottom: "1em" }}>Preparing Login...</div>
      <CircularProgress disableShrink />
    </Container>
  );
}

function ErrorComponent(error: any) {
  return <p>An Error Occurred: {error}</p>;
}

const SignIn: FC<SignInProps> = () => {
  return (
    <Container
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          loadingComponent={LoadingComponent}
          errorComponent={ErrorComponent}
        ></MsalAuthenticationTemplate>
      </>
      <p
        style={{
          position: "absolute",
          bottom: "1em",
          width: "100%",
          left: 0,
          textAlign: "center",
          fontSize: "0.8em",
          opacity: 0.5,
        }}
      ></p>
    </Container>
  );
};

export default SignIn;
