import { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LiveCoverage from "../../components/LiveCoverage/LiveCoverage";
import NewsEvents from "../../components/NewsEvents/NewsEvents";
import NewsEventSummary from "../../components/NewsEventSummary/NewsEventSummary";
import { getUserSelectedDate } from "../../utils/api";
import { CmEvent, MinisterEvent, pageRoutingInterval } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { SocialMediaRoutePath } from "../../common/helper/AppMenu.Config";

export const ChannelDashboard = () => {
  const [userDate] = useState(getUserSelectedDate());
  const navigation = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigation(SocialMediaRoutePath);
    }, pageRoutingInterval);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <div className="flex flex-col">
          <div className="grid pt-2 sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <div className="card ml-5 mr-5">
              <NewsEvents title="CM Events Coverage" eventName={CmEvent} date={userDate} />
            </div>

            <div className="card mr-5 ml-5 mt-5 lg:ml-0 lg:mt-0 pt-50 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5">
              <NewsEventSummary title="CM Events Summary" eventName={CmEvent} date={userDate} />
            </div>
          </div>
          <div className="grid pt-2 mt-4 sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2">
            <div className="card ml-5 mr-5">
              <NewsEvents title="Minister Events Coverage" eventName={MinisterEvent} date={userDate} />
            </div>
            <div className="card ml-5 mt-5 mr-5 lg:ml-0 lg:mt-0 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5">
              <LiveCoverage title="Live Coverage Gap" date={userDate} />
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <></>
      </UnauthenticatedTemplate>
    </>
  );
};
