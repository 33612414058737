import { Chart as ChartJS, ChartArea } from "chart.js";
import pattern from "patternomaly";

export const getGradient = (chart: ChartJS, label: string, startFrom?: string) => {
  const colors = getGradientColor(label);
  return createGradient(chart.ctx, chart.chartArea, colors, startFrom);
};

function getGradients(chart: ChartJS, labels: string[], startFrom?: string) {
  if (!labels) return getGradient(chart, "");

  return labels.map((item: any) => {
    return getGradient(chart, item);
  });
}

export enum Colors{
  white ='#FFFFFF',
  black='#000000',
  gray='#707070ad',
  paleViolet='#DFB9FF',
  violet='#BB5DFF',
  lightGray='#70707072',
  lightWhite='#00000080',
  darkPink='#ff3366',
  lightPink='#FF8181',
  darkRed='#880000',
  skyBlue='#85D4FD',
  yellow='#CC840D',
  lightYellow='#FFD084',
  darkYellow='#FFC400',
  lightBlue='#71D3FF',
  green='#4DC900',
  lightGreen='#2d925c',
  seaGreen='#6DF7AD',
  lightRed='#FF4A4A',
  deepSkyBlue='#00BFFF',
  red='#C41313',
  sunsetOrange='#FF5050',
  cyanBlue='#0050EF',
  softBlue='#6598FD',
  strongOrange='#B9632E',
  lightOrange='#FC8049',
  deepBlue='#3685F5',
  lightViolet='#AB57CB',
  SprayBlue='#70F5FC',
  magentaViolet='#E0A4FA',
  wheat='#E3A549',
  lightWheat='#FFD084',
  crayellow='#F4D242',
  brightLilac='#DA8EE7',
  mauve='#E1B5F4',
  electricBlue='#6DEFFC'
}

function getGradientColor(name: string) {
  const defaultColor = [Colors.white];

  if (!name) return defaultColor;

  const index = name.indexOf(" ");
  if (index > 0) {
    name = name.substring(0, index);
  }
  switch (name) {
    case "Positive":
    case "AIADMK":
    case "ADMK":
      return [Colors.lightGreen, Colors.seaGreen];
    case "Negative":
    case "DMK":
      return [Colors.red, Colors.sunsetOrange];
    case "Neutral":
      return [Colors.yellow, Colors.lightYellow];
    case "Government":
    case "Gov":
      return [Colors.cyanBlue, Colors.softBlue];
    case "BJP":
      return [Colors.strongOrange, Colors.lightOrange];
    case "LiveCoverage":
      return [Colors.deepBlue, Colors.SprayBlue];
    case "Others":
      return [Colors.lightViolet, Colors.magentaViolet];
    case "CM":
      return [Colors.wheat, Colors.lightWheat];
    case "CmSummary":
      return [Colors.crayellow, Colors.crayellow];
    case "Minister":
      return [Colors.brightLilac, Colors.mauve];
    case "show":
      return [Colors.deepBlue, Colors.electricBlue, Colors.deepBlue];
    case "break":
      return [Colors.lightPink];
    default:
      return defaultColor;
  }
}

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea, color: string[], startFrom?: string) {
  const gradient =
    startFrom && startFrom === "left"
      ? ctx.createLinearGradient(0, area.left, 500, area.right / 5)
      : ctx.createLinearGradient(0, area.bottom, 0, area.top);

  color.forEach((element: string, index: number) => {
    index % 2 === 0 ? gradient.addColorStop(0, element) : gradient.addColorStop(1, element);
  });
  return gradient;
}

export const getChartWithGradientColor = (chartData: any, chart: ChartJS, startFrom?: string) => {
  return {
    ...chartData,
    datasets: chartData.datasets.map((item: any) => ({
      ...item,
      backgroundColor: item.backgroundColor ? item.backgroundColor : getGradients(chart, item.gradientLabels, startFrom),
      borderColor: item.borderColor ? item.borderColor : getGradients(chart, item.gradientLabels, startFrom),
    })),
  };
};

export const patternBackgroundColor = (shapeType: any, backgroundColor: string, lineColor: string, size: number) => {
  return pattern.draw(shapeType, backgroundColor, lineColor, size);
};
