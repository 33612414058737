import React, { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import DoughnutChart from "../Charts/DoughnutChart/DoughnutChart";
import { getSubjectSplit } from "../../api/PrintMediaServices";
import { SUBJECT_SPLIT_DATA } from "../../api/helpers/DataQueryKeys";
import "./SubjectSplit.scss";
import { getValidDate } from "../../utils/api";
import { errorString, getSubjectSplitLegend, printMediaNoDataString, ShowLegend, showLoadingIcon, showText } from "../../utils/helper";
import { ISubjectSplitItem } from "../../models/PrintMedia";
import { getSentimentChartOption } from "../../utils/SentimentUtility";
// import * as Sentry from "@sentry/react";

interface ISubjectSplitProps {
  date?: Date;
  title: string;
}

const cutout = "60%";

const options = getSentimentChartOption();

const getSubjectSplitChartData = (subjectSplit: ISubjectSplitItem): any => {
  const labels = Object.keys(subjectSplit as Object).map((item: any) => item);
  return {
    labels: labels,
    datasets: [
      {
        data: Object.values(subjectSplit as Object).map((item: any) => item),
        borderWidth: 1,
        cutout: cutout,
        responsive: true,
        maintainAspectRatio: false,
        gradientLabels: labels,
      },
    ],
  };
};

const SubjectSplit: FC<ISubjectSplitProps> = (props) => {
  const date = getValidDate(props.date);
  const { data: subjectSplit, isLoading, error } = useQuery([SUBJECT_SPLIT_DATA], () => getSubjectSplit(date));
  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  const chartData = subjectSplit ? getSubjectSplitChartData(subjectSplit) : null;

  return (
    <div className="SubjectSplit" data-testid="SubjectSplit">
      <div className="grid">
        <h1 className="text-chartTitle pl-5 mt-5">{props.title}</h1>
      </div>

      {chartData ? (
        <div className="flex justify-evenly p-10 gap-4">
          <DoughnutChart chartData={chartData} options={options} className={"grid w-1/3 sm:w-1/5 md:w-1/5 lg:w-2/3"}></DoughnutChart>
          <div className="mt-2 lg:mt-5">
            <ShowLegend data={subjectSplit} legends={getSubjectSplitLegend()} showOrder={"row"} />
          </div>
        </div>
      ) : (
        showText(printMediaNoDataString)
      )}
    </div>
  );
};

export default SubjectSplit;
