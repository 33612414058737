import React, { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { NEWS_ARTICLES_DATA } from "../../api/helpers/DataQueryKeys";
import { getNewsArticles } from "../../api/PrintMediaServices";
import BarChart from "../Charts/BarChart/BarChart";
import "./NewsArticles.scss";
import { getValidDate } from "../../utils/api";
import { errorString, getSentimentLegend, printMediaNoDataString, ShowLegend, showLoadingIcon, showText } from "../../utils/helper";
import { BarChartOptionsProps, getBarChartOptions } from "../../utils/BarChartUtility";
// import * as Sentry from "@sentry/react";

interface NewsArticlesProps {
  date?: Date;
}

const getNewsArticlesSubjects = () => {
  return ["DMK", "Government"];
};

const NewsArticles: FC<NewsArticlesProps> = (props) => {
  const date = getValidDate(props.date);
  const subjects = getNewsArticlesSubjects();
  const { data: newsArticles, isLoading, error } = useQuery([NEWS_ARTICLES_DATA], () => getNewsArticles(subjects, date));

  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  const chartData = {
    labels: newsArticles?.map((item: any) => {
      let index = item.name.indexOf(" ");
      let name: string[] = [];
      if (index >= 0) {
        name.push(item.name.substring(0, index));
        name.push(item.name.substring(index + 1, item.name.length));
        return name;
      } else {
        return item.name;
      }
    }),
    datasets: [
      {
        label: "Negative",
        data: newsArticles ? newsArticles.map((item: any) => Number(item.Negative) || 0) : [0],
        stack: "Negative",
        barThickness: 18,
        gradientLabels: ["Negative"],
      },
      {
        label: "Neutral",
        data: newsArticles ? newsArticles.map((item: any) => Number(item.Neutral) || 0) : [0],
        stack: "Neutral",
        barThickness: 18,
        gradientLabels: ["Neutral"],
      },
      {
        label: "Positive",
        data: newsArticles ? newsArticles.map((item: any) => Number(item.Positive) || 0) : [0],
        stack: "Positive",
        barThickness: 18,
        gradientLabels: ["Positive"],
      },
    ],
  };

  const barChartProps: BarChartOptionsProps = {
    stepSize: Math.round(chartData ? Math.max(...chartData.datasets[0].data) / 3 : 5),
    layout: { padding: { top: 50, right: 50, bottom: 0, left: 30 } },
  };

  const chartOptions = getBarChartOptions(barChartProps);

  return (
    <div className="NewsArticles " data-testid="NewsArticles">
      <div className="flex justify-between">
        <h1 className="text-chartTitle pl-5 mt-5">News Articles</h1>
        <div className="text-subTitle pt-6 mr-5">
          <ShowLegend data={chartData} legends={getSentimentLegend()} showPercentage={false}></ShowLegend>
        </div>
      </div>

      {newsArticles ? (
        <div className="">
          <BarChart chartData={chartData} chartOptions={chartOptions} />
        </div>
      ) : (
        showText(printMediaNoDataString)
      )}
    </div>
  );
};

export default NewsArticles;
