import { URL } from "../config/URL.Config";
import { INewsArticlesItems, ISubjectSplitItem } from "../models/PrintMedia";
import { ITopNewsItems, ISentimentItem } from "../models/Common";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getPrintMediaTopNews = async (count: number, date: string) => {
  console.log(count,date)
  let topNews: ITopNewsItems;
  let props  =  {'count':count,'date':date}
  const res = await instance.post(`${URL.printMedia.topNews}`, props);
  topNews = res.data;
  return topNews;
};

export const getSentimentNarration = async (subject: string, date: string) => {
  let props  =  {'subject':subject,'date':date}
  let sentimentData: ISentimentItem;
  const res = await instance.post(`${URL.printMedia.sentimentNarration}`,props);
  sentimentData = Object.keys(res.data).length > 0 ? res.data : null;
  console.log("sentimentData",sentimentData)
  return sentimentData;
};

export const getSubjectSplit = async (date: string) => {
  let subjectSplitData: ISubjectSplitItem;
  let props  =  {'date':date}
  const res = await instance.post(`${URL.printMedia.subjectSplit}`,props);
  subjectSplitData = Object.keys(res.data).length > 0 ? res.data : null;
  return subjectSplitData;
};

export const getNewsArticles = async (subjects: string[], date: string) => {
  let newsArticlesData: INewsArticlesItems;
  let props  =  {'subject':subjects,'date':date}
  const res = await instance.post(`${URL.printMedia.newsArticles}`,props);
  newsArticlesData = Object.keys(res.data).length > 0 ? res.data : null;
  console.log(newsArticlesData)
  return newsArticlesData;
};
