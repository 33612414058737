import { FC, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import "./TopNews.scss";
import { SentimentNarrationIcon } from "../../common/icons/SentimentNarrationIcon";
import { calcToDateForTweet, convertToUTC, getValidDate } from "../../utils/api";
import { errorString, noDataString, printMediaNoDataString, showLoadingIcon, showText } from "../../utils/helper";
import { PrintMediaTitle, SocialMediaTitle } from "../../common/helper/AppMenu.Config";
import { ITopNewsItems } from "../../models/Common";
import { getPrintMediaTopNews } from "../../api/PrintMediaServices";
import { getSocialMediaTopNews } from "../../api/SocialMediaServices";
import { PRINT_TOP_NEWS, SOCIAL_TOP_NEWS, TV_TOP_NEWS } from "../../api/helpers/DataQueryKeys";
// import * as Sentry from "@sentry/react";
import uuid from "react-uuid";

interface TopNewsProps {
  title: string;
  media: string;
  count: number;
  date?: Date; //yyyy/mm/dd
  hasSentiment: boolean;
}

const TopNews: FC<TopNewsProps> = (props) => {
  const [date] = useState(getValidDate(props.date));

  const tweetToDate = calcToDateForTweet(props.date);
  const toDate = convertToUTC({ date: tweetToDate });
  tweetToDate.setHours(tweetToDate.getHours() - 6);
  const fromDate = props.media === PrintMediaTitle ? convertToUTC({ date: new Date(date.replace(/-/g, "/")) }) : convertToUTC({ date: tweetToDate });

  const [topNews, setData] = useState<ITopNewsItems>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const query = useQueryClient();

  const print: boolean = props.media === PrintMediaTitle;
  const social: boolean = props.media === SocialMediaTitle;

  const noDataText = print ? printMediaNoDataString : noDataString;

  const {
    data: printData,
    isLoading: printDataIsLoading,
    error: printDataErr,
  } = useQuery([PRINT_TOP_NEWS, print], () => getPrintMediaTopNews(props.count, date), { enabled: print });
  const {
    data: socialData,
    isLoading: socialDataIsLoading,
    error: socialDataErr,
  } = useQuery([SOCIAL_TOP_NEWS, social], () => getSocialMediaTopNews(props.count, fromDate, toDate), { enabled: social });

  useEffect(() => {
    if (print) {
      setData(printData);
      setLoading(printDataIsLoading);
      setError(printDataErr);
    } else if (social) {
      setData(socialData);
      setLoading(socialDataIsLoading);
      setError(socialDataErr);
    }

    return () => {
      query.invalidateQueries([PRINT_TOP_NEWS, TV_TOP_NEWS, SOCIAL_TOP_NEWS]);
      setLoading(false);
      setError([]);
    };
  }, [printData, socialData, printDataErr, socialDataErr]);

  if (isLoading === true) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  return (
    <div className="topNews p-5" data-testid="TopNews">
      <h1 className="text-chartTitle"> {props.title} </h1>

      {topNews && topNews.length ? (
        <ul className="mt-5">
          {topNews?.map((news: any) => (
            <>
              <li key={uuid()} className="newsItem text-newsText leading-relaxed tracking-wide p-3">
                <div className="flex justify-between">
                  <span className="topNews">{news.title}</span>
                  {props.hasSentiment ? (
                    <div className="text-right ml-24 pr-5 pt-2">
                      <SentimentNarrationIcon narration={news.sentimentNarration} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            </>
          ))}
        </ul>
      ) : (
        showText(noDataText)
      )}
    </div>
  );
};

export default TopNews;
