export const TvChannelsIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" viewBox="0 0 36 40">
            <g id="Group_613" data-name="Group 613" transform="translate(-814.044 -1371.451)">
                <g id="Rectangle_4318" data-name="Rectangle 4318" transform="translate(823.647 1400.989)" fill="none" stroke="#dfb9ff" stroke-width="1.5">
                    <rect width="20.06" height="1.824" rx="0.912" stroke="none" />
                    <rect x="0.75" y="0.75" width="18.56" height="0.324" rx="0.162" fill="none" />
                </g>
                <g id="Group_612" data-name="Group 612" transform="matrix(0.921, 0.391, -0.391, 0.921, 820.19, 1374.658)">
                    <g id="Subtraction_1" data-name="Subtraction 1" transform="translate(0.664 0)" fill="none">
                        <path d="M.56,5.658h0a3.7,3.7,0,1,1,6.277,0,1.915,1.915,0,0,0-.809-.178H1.37a1.9,1.9,0,0,0-.809.178Z" stroke="none" />
                        <path d="M 5.879252433776855 3.979212284088135 C 5.891101837158203 3.886436939239502 5.89709997177124 3.792569160461426 5.89709997177124 3.69811224937439 C 5.89709997177124 2.486072301864624 4.910629749298096 1.500012397766113 3.698100090026855 1.500012397766113 C 2.486059904098511 1.500012397766113 1.5 2.486072301864624 1.5 3.69811224937439 C 1.5 3.792546033859253 1.505993247032166 3.886451244354248 1.51780366897583 3.979212284088135 L 5.879252433776855 3.979212284088135 M 0.5601699948310852 5.657942295074463 L 0.5597000122070312 5.657192230224609 C 0.1935399919748306 5.0702223777771 -1.411438077525418e-08 4.392802238464355 -1.411438077525418e-08 3.69811224937439 C -1.411438077525418e-08 1.658972382545471 1.658959984779358 1.233963030244922e-05 3.698100090026855 1.233963030244922e-05 C 5.737740039825439 1.233963030244922e-05 7.39709997177124 1.658972382545471 7.39709997177124 3.69811224937439 C 7.39709997177124 4.393192291259766 7.203219890594482 5.070562362670898 6.836430072784424 5.656992435455322 C 6.581270217895508 5.539152145385742 6.309460163116455 5.479212284088135 6.027299880981445 5.479212284088135 L 1.369799971580505 5.479212284088135 C 1.08722996711731 5.479212284088135 0.8151000142097473 5.539222240447998 0.5609700083732605 5.657572269439697 L 0.5601699948310852 5.657942295074463 Z" stroke="none" fill="#dfb9ff" />
                    </g>
                    <g id="Rectangle_4320" data-name="Rectangle 4320" transform="translate(0 4.448)" fill="none" stroke="#dfb9ff" stroke-width="1.5">
                        <rect width="8.504" height="3.603" rx="1" stroke="none" />
                        <rect x="0.75" y="0.75" width="7.004" height="2.103" rx="0.25" fill="none" />
                    </g>
                    <path id="Path_10702" data-name="Path 10702" d="M0,0H4.208L3.092,10.749H1.424Z" transform="translate(2.181 7.601)" fill="none" stroke="#dfb9ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
                <path id="Path_10703" data-name="Path 10703" d="M846.419,1292.76h17.3a3.723,3.723,0,0,1,2.646.769,3.585,3.585,0,0,1,.975,2.5v13.462a3.541,3.541,0,0,1-.975,2.346,3.327,3.327,0,0,1-2.176,1H839.519a3.5,3.5,0,0,1-2.233-.61,2.442,2.442,0,0,1-.9-1.737" transform="translate(-17.85 85.961)" fill="none" stroke="#dfb9ff" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="1.5" />
                <line id="Line_92" data-name="Line 92" x1="4.753" y1="6.417" transform="translate(829.545 1372.5)" fill="none" stroke="#dfb9ff" stroke-linecap="round" stroke-width="1.5" />
                <line id="Line_93" data-name="Line 93" y1="4.753" x2="3.395" transform="translate(834.87 1374.165)" fill="none" stroke="#dfb9ff" stroke-linecap="round" stroke-width="1.5" />
                <text id="TV" transform="translate(829.298 1392.765)" fill="#af96cc" font-size="8" font-family="Avenir-Black, Avenir" font-weight="800"><tspan x="0" y="0">TV</tspan></text>
            </g>
        </svg>
    )
}