import { Routes, Route, Navigate } from "react-router-dom";
import { PrintMediaDasboard } from "./pages/PrintMedia/PrintMedia";
import { SocialMediaDashboard } from "./pages/SocialMedia/SocialMedia";
import { ChannelDashboard } from "./pages/TVChannels/TvChannels";
import Login from "./pages/Login/Login";
import Registration from "./pages/Registration/Registration";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PrintMediaRoutePath, SocialMediaRoutePath, TvChannelsRoutePath } from "./common/helper/AppMenu.Config";

const AppRoutes = () => (
  <div className="mt-3" data-testid="Master">
    <AuthenticatedTemplate>
      <Routes>
        <Route path="/" element={<PrintMediaDasboard />} />
        <Route path={PrintMediaRoutePath} element={<PrintMediaDasboard />} />
        <Route path={TvChannelsRoutePath} element={<ChannelDashboard />} />
        <Route path={SocialMediaRoutePath} element={<SocialMediaDashboard />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Registration />} />
      </Routes>
    </UnauthenticatedTemplate>
  </div>
);

export default AppRoutes;
