export const SocialMediaIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 36 40">
            <g id="Group_617" data-name="Group 617" transform="translate(-176.832 -1130.09)">
                <path id="Path_10704" data-name="Path 10704" d="M109.172,1221.025v-5.1a3.2,3.2,0,0,1,.607-2.163,4.161,4.161,0,0,1,2.443-1.014h13.663a4.763,4.763,0,0,1,2.439,1.014,2.347,2.347,0,0,1,.642,1.865v8.875" transform="translate(75.41 -81.908)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <path id="Path_10705" data-name="Path 10705" d="M128.919,1232.8v4.292a3.751,3.751,0,0,1-.464,2.31,2.926,2.926,0,0,1-2.245.868H112.578s-2.241-.009-2.924-.868a2.941,2.941,0,0,1-.529-2.011v-5.527" transform="translate(75.175 -77.155)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <g id="Group_614" data-name="Group 614" transform="translate(176.832 1140.847)">
                    <path id="Path_10706" data-name="Path 10706" d="M0,0H12.131V12.131H0Z" fill="none" />
                    <rect id="Rectangle_4327" data-name="Rectangle 4327" width="8.925" height="8.925" rx="2" transform="translate(1.603 1.603)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <circle id="Ellipse_13" data-name="Ellipse 13" cx="1.785" cy="1.785" r="1.785" transform="translate(4.28 4.28)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <line id="Line_94" data-name="Line 94" y2="0.001" transform="translate(8.34 3.791)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </g>
                <g id="Group_615" data-name="Group 615" transform="translate(201 1141.588)">
                    <path id="Path_10707" data-name="Path 10707" d="M0,0H11.39V11.39H0Z" fill="none" />
                    <path id="Path_10708" data-name="Path 10708" d="M4.9,7.322v3.8a.475.475,0,0,1-.475.475H3.475A.475.475,0,0,1,3,11.119V7.8a.475.475,0,0,1,.475-.475H4.9a1.9,1.9,0,0,0,1.9-1.9V4.949a.949.949,0,1,1,1.9,0V7.322h1.424a.949.949,0,0,1,.949.949l-.475,2.373c-.14.6-.527.985-.949.949H6.322A1.424,1.424,0,0,1,4.9,10.17" transform="translate(-1.576 -2.102)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </g>
                <g id="Group_616" data-name="Group 616" transform="translate(189.887 1135.405)">
                    <path id="Path_10709" data-name="Path 10709" d="M0,0H9.189V9.189H0Z" fill="none" />
                    <path id="Path_10710" data-name="Path 10710" d="M9.658,3.942a6.87,6.87,0,0,1-1.149.379,1.451,1.451,0,0,0-1.677-.282,1.506,1.506,0,0,0-1,1.431v.383A3.6,3.6,0,0,1,2.766,4.321,2.886,2.886,0,0,0,4.3,8.533,3.774,3.774,0,0,1,2,9.3a5.14,5.14,0,0,0,3.842.581,4.214,4.214,0,0,0,2.93-2.964,5.3,5.3,0,0,0,.19-1.437c0-.1.578-1.061.7-1.537Z" transform="translate(-1.234 -2.406)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </g>
                <g id="Group_622" data-name="Group 622" transform="translate(188.197 1147.705)">
                    <path id="Path_10711" data-name="Path 10711" d="M0,0H12.827V12.635H0Z" fill="none" />
                    <rect id="Rectangle_4328" data-name="Rectangle 4328" width="10" height="8" rx="2" transform="translate(1.5 2.135)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                    <path id="Path_10712" data-name="Path 10712" d="M10,9l2.708,1.625L10,12.25Z" transform="translate(-4.674 -4.307)" fill="none" stroke="#85d4fd" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                </g>
            </g>
        </svg>
    )
}