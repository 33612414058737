import { useState, useEffect } from "react";
import TopNews from "../../components/TopNews/TopNews";
import TweetSentiment from "../../components/TweetSentiment/TweetSentiment";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import WordCloud from "../../components/WordCloud/WordCloud";
import TweetTrend from "../../components/TweetTrend/TweetTrend";
import { SocialMediaTitle } from "../../common/helper/AppMenu.Config";
import { getUserSelectedDate } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { pageRoutingInterval } from "../../utils/helper";

export const SocialMediaDashboard = () => {
  const [userDate] = useState(getUserSelectedDate());
  const navigation = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigation("/");
    }, pageRoutingInterval);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <div className="flex flex-col ">
          <div className="ml-5 mt-2">
            <div className="grid grid-cols-12">
              <div className="card mr-5 col-span-12 lg:col-span-4">
                <TopNews media={SocialMediaTitle} title="Top Tweets" count={5} hasSentiment={false} date={userDate}></TopNews>
              </div>

              <div className="card mr-5 mt-5 lg:mt-0 col-span-12 lg:col-span-5">
                <TweetSentiment title="Sentiment" date={userDate} />
              </div>
              <div className="card mt-5 mr-5 lg:mt-0 col-span-12 lg:col-span-3">
                <WordCloud title="Word Cloud" date={userDate}></WordCloud>
              </div>
            </div>

            <div className="card mt-5 mr-5">
              <TweetTrend title="Tweet Trend" date={userDate}></TweetTrend>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
    </>
  );
};
