import * as msal from "@azure/msal-browser";
import * as authStorage from "./auth.service";
import { store } from "../store/store";
import { setError, setUser } from "../store/user.slice";
import jwtDecode from "jwt-decode";

let knownAuthorities = [];

if (
  !process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_CLIENT_ID ||
  !process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_AUTHORITY ||
  !process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_KNOWN_AUTHORITIES
) {
  console.log(process.env);
  console.warn("Missing environment variables for Azure AD");
}

if (process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_KNOWN_AUTHORITIES) {
  knownAuthorities = JSON.parse(
    process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_KNOWN_AUTHORITIES
  );
}

const msalConfig: msal.Configuration = {
  auth: {
    clientId: process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_CLIENT_ID as string,
    authority: process.env.REACT_APP_NEXT_PUBLIC_AZURE_AD_AUTHORITY,
    knownAuthorities,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_LOGIN_SCOPE || ""],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

msalInstance
  .handleRedirectPromise()
  .then((authResponse) => {
    if (authResponse) {
      console.log("authResponse", authResponse);

      const token: any = jwtDecode(authResponse.accessToken);
      if (!checkAccess(token["extension_AllowedApps"])) {
        logoutRequest();
      }

      authStorage.setAuthDetails(
        authResponse.accessToken,
        authResponse.idToken
      );
      store.dispatch(setUser(authResponse.account));
    }
  })
  .catch((error) => {
    console.log("error", error);
  });

msalInstance.addEventCallback((message) => {
  if (message.error) {
    console.log("message", message.error.message);
    if (message.error.name === "InteractionRequiredAuthError") {
      msalInstance.logoutRedirect();
    }
    authStorage.setAuthDetails("", "");
    store.dispatch(setError(true));
  }
});

export const logoutRequest = () => {
  msalInstance.logoutRedirect();
};

export { msalInstance };

const checkAccess = (allowedApps: string) => {
  return allowedApps.indexOf("MediaMonitoring") !== -1;
};
