type SentimentNarrationIconProps = {
    narration: string;
}

export const SentimentNarrationIcon: React.FC<SentimentNarrationIconProps> = ({ narration }) => {
    if (narration === "Positive") {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23 20">
                <path id="Polygon_1"
                    data-name="Polygon 1"
                    d="M10.633,1.508a1,1,0,0,1,1.734,0L22.138,18.5a1,1,0,0,1-.867,1.5H1.729a1,1,0,0,1-.867-1.5Z"
                    fill="#48ff6b" />
            </svg>
        )
    } else if (narration === "Negative") {
        return (
            < svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23 20" >
                <path id="Polygon_2"
                    data-name="Polygon 2"
                    d="M10.633,1.508a1,1,0,0,1,1.734,0L22.138,18.5a1,1,0,0,1-.867,1.5H1.729a1,1,0,0,1-.867-1.5Z"
                    transform="translate(23 20) rotate(180)"
                    fill="#ff5050" />
            </svg >
        )
    } else {
        return (
            <>
            </>
        )
    }
}
