import { useQuery } from "@tanstack/react-query";
import React, { FC, useState } from "react";
import ReactWordcloud, { MinMaxPair, Word } from "react-wordcloud";
import { SAS_TOKEN_DATA, TWEETS_WORD_CLOUD_DATA } from "../../api/helpers/DataQueryKeys";
import { getSasToken, getTwitterWordCloudFilePath, getWordCloud } from "../../api/SocialMediaServices";
import { errorString, noDataString, showLoadingIcon, showText, WordCloudBlob } from "../../utils/helper";
import "./WordCloud.scss";
// import * as Sentry from "@sentry/react";
import { Colors } from "../Charts/Helpers/ChartUtility";

interface WordCloudProps {
  title: string;
  date?: Date;
}

const options = {
  colors: [Colors.yellow, Colors.lightYellow],
  fontFamily: "arial",
  fontSizes: [10, 60] as MinMaxPair,
  deterministic: true,
  enableOptimizations: true,
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 1,
  rotations: 1,
  rotationAngles: [0, 180] as MinMaxPair,
  tooltipOptions: {
    allowHTML: true,
    appendTo: document.body,
    arrow: true,
    interactive: false,
  },
};

const WordCloud: FC<WordCloudProps> = (props) => {
  const [userDate] = useState(props.date);
  const [filePath] = useState(getTwitterWordCloudFilePath(userDate));
  const { data: sasTokenData } = useQuery([SAS_TOKEN_DATA], () => getSasToken(WordCloudBlob));
  const sasToken = sasTokenData;

  const {
    data: wordCloudData,
    isLoading,
    error,
  } = useQuery([TWEETS_WORD_CLOUD_DATA, sasToken], () => getWordCloud(filePath + sasToken), {
    enabled: !!sasToken,
  });

  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  const wordChartData = wordCloudData as Word[];

  const callbacks = {
    getWordColor: (word: any) => {
      if (word.sentiment === "positive") {
        return Colors.green;
      } else if (word.sentiment === "negative") {
        return Colors.red;
      } else if (word.sentiment === "neutral") {
        return Colors.yellow;
      }
    },

    onWordClick: (word: any) => window.open(word[word.sentiment + "TweetsUrl"][0], "_blank"),
  };

  const showFetching = () => {
    return <>{isLoading ? showLoadingIcon() : showText(noDataString)}</>;
  };

  return (
    <div className="WordCloud" data-testid="WordCloud">
      <h1 className="text-chartTitle pl-5 mt-5"> {props.title} </h1>
      <div className="h-11/12 mt-5 !wordCloud">
        {wordChartData && wordChartData.length ? <ReactWordcloud callbacks={callbacks} words={wordChartData} options={options} /> : showFetching()}
      </div>
    </div>
  );
};

export default WordCloud;
