import { URL } from "../config/URL.Config";
import { ITopNewsItems } from "../models/Common";
import { ITweetsentimentItems, ITweetTrendItems, IWordCloudItems } from "../models/SocialMedia";
import { calcToDateForTweet, convertToUTC } from "../utils/api";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getSocialMediaTopNews = async (count: number, from: string, to: string) => {
  let topNews: ITopNewsItems;
  const res = await instance.get(`${URL.socialMedia.topNews}/${count}/${from}/${to}`);
  topNews = res.data;
  return topNews;
};

export const getTweetsSentiment = async (from: string, to: string) => {
  let tweetsSentiment: ITweetsentimentItems;
  const res = await instance.get(`${URL.socialMedia.tweetsSentiment}/${from}/${to}`);
  tweetsSentiment = res.data;
  return tweetsSentiment;
};

export const getSasToken = async (blobName: string) => {
  const url = `${URL.token.sasToken}/${blobName}`;
  const res = await instance.get(url, { responseType: "text" as "json" });
  return res?.data ?? "";
};

export const getTwitterWordCloudFilePath = (date: any) => {
  const tweetToDate = calcToDateForTweet(date);
  const endHr = convertToUTC({ date: tweetToDate, strFormat: "HH" });
  tweetToDate.setHours(tweetToDate.getHours() - 6);
  const fromDate = convertToUTC({ date: tweetToDate, strFormat: "YYYY-MM-DD" });
  const startHr = convertToUTC({ date: tweetToDate, strFormat: "HH" });
  const filePath = `social-media/${fromDate}/${startHr}-${endHr}.json`;
  return filePath;
};

export const getWordCloud = async (fileName: string) => {
  const url = `${process.env.REACT_APP_BLOB_URL}/${process.env.REACT_APP_WORD_CLOUD_BLOB}/${fileName}`;
  const res = await fetch(url);
  return res.json();
};

export const getTweetsWordCloud = async (from: string, to: string) => {
  let tweetsWordCloud: IWordCloudItems;
  const res = await instance.get(`${URL.socialMedia.tweetsWordCloud}/${from}/${to}`);
  tweetsWordCloud = res.data;
  return tweetsWordCloud;
};

export const getTweetTrend = async (from: string, to: string) => {
  let tweetTrend: ITweetTrendItems;
  const res = await instance.get(`${URL.socialMedia.tweetsTrend}/${from}/${to}`);
  tweetTrend = res.data;
  return tweetTrend;
};
