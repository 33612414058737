import React, { FC } from 'react';
import './Registration.scss';

interface RegistrationProps {}

const Registration: FC<RegistrationProps> = () => (
  <div className="Registration" data-testid="Registration">
    Registration Component
  </div>
);

export default Registration;
