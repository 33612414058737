export const PrintMediaIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 25 30">
            <g id="Group_609" data-name="Group 609" transform="translate(-1408.168 -1194.594)">
                <g id="Group_609-2" data-name="Group 609" transform="translate(1409.174 1195.594)">
                    <path id="Path_10698" data-name="Path 10698" d="M1141.485,1407.594h23.149V1429.5s.095,4.284-4.117,4.165a1.5,1.5,0,0,1-.245.007c-2.952-.033-20.885,0-20.885,0a3.049,3.049,0,0,0,2.1-2.939C1141.487,1428.4,1141.485,1407.594,1141.485,1407.594Z" transform="translate(-1136.799 -1407.594)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <path id="Path_10699" data-name="Path 10699" d="M1092.151,1650.493h-4.578s-.1,7.29,0,10.515c.709,3.126,2.926,2.507,2.926,2.507" transform="translate(-1087.526 -1637.464)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <rect id="Rectangle_4316" data-name="Rectangle 4316" width="6.427" height="5.748" rx="1" transform="translate(8.784 4.385)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    <line id="Line_87" data-name="Line 87" x2="3.664" transform="translate(19.736 5.292)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-width="2" />
                    <line id="Line_88" data-name="Line 88" x2="3.664" transform="translate(19.736 8.312)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-width="2" />
                    <line id="Line_89" data-name="Line 89" x2="14.616" transform="translate(8.784 14.168)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-width="2" />
                    <line id="Line_90" data-name="Line 90" x2="14.616" transform="translate(8.784 18.203)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-width="2" />
                    <line id="Line_91" data-name="Line 91" x2="14.616" transform="translate(8.784 22.122)" fill="none" stroke="#fcf896" stroke-linecap="round" stroke-width="2" />
                </g>
            </g>
        </svg>
    )
}