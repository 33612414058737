import { useQuery } from "@tanstack/react-query";
import React, { FC, useState } from "react";
import { DMK_SENTIMENT_DATA, GOV_SENTIMENT_DATA } from "../../api/helpers/DataQueryKeys";
import { getSentimentNarration } from "../../api/PrintMediaServices";
import { getValidDate } from "../../utils/api";
import { errorString, getSentimentLegend, printMediaNoDataString, ShowLegend, showLoadingIcon, showText } from "../../utils/helper";
import { createSentimentChartData, getSentimentChartOption } from "../../utils/SentimentUtility";
import DoughnutChart from "../Charts/DoughnutChart/DoughnutChart";
import "./SentimentNarration.scss";
// import * as Sentry from "@sentry/react";

interface SentimentNarrationProps {
  date?: Date;
}

const SentimentNarration: FC<SentimentNarrationProps> = (props) => {
  const [date] = useState(getValidDate(props.date));
  const dmkChart = "DMK";
  const govChart = "Government";
  const {
    data: dmk,
    isLoading: dmkIsLoaing,
    error: dmkError,
  } = useQuery([DMK_SENTIMENT_DATA, dmkChart], () => getSentimentNarration(dmkChart, date));

  const {
    data: gov,
    isLoading: govIsLoading,
    error: govError,
  } = useQuery([GOV_SENTIMENT_DATA, govChart], () => getSentimentNarration(govChart, date));

  if (dmkIsLoaing || govIsLoading) {
    return showLoadingIcon();
  }

  if (dmkError || govError) {
    // Sentry.captureException(dmkError ? dmkError : govError);
    return showText(errorString);
  }

  const dmkChartData = dmk ? createSentimentChartData(dmk, "60%") : null;
  const govChartData = gov ? createSentimentChartData(gov, "60%") : null;
  const chartOption = getSentimentChartOption();

  return (
    <div className="SentimentNarration mt-5" data-testid="SentimentNarration">
      <div className="grid grid-cols-2 justify-between">
        <h1 className="grid place-items-center text-chartTitle">{govChart}</h1>
        <h1 className="grid place-items-center text-chartTitle">{dmkChart}</h1>
      </div>

      <div className="grid grid-cols-2 p-5 mt-3">
        <div className="grid justify-items-center sentimentChartDividen">
          {govChartData ? (
            <DoughnutChart chartData={govChartData} options={chartOption} className={"grid w-1/3 sm:w-1/5 md:w-1/3 md:p-1 lg:w-2/3"}></DoughnutChart>
          ) : (
            showText(printMediaNoDataString)
          )}
        </div>
        <div className="grid justify-items-center">
          {dmkChartData ? (
            <DoughnutChart chartData={dmkChartData} options={chartOption} className={"grid w-1/3 sm:w-1/5 md:w-1/3 md:p-1  lg:w-2/3"}></DoughnutChart>
          ) : (
            showText(printMediaNoDataString)
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 justify-between">
        <div className="grid place-items-center mb-2 mr-8">{gov ? <ShowLegend data={gov} legends={getSentimentLegend()} /> : <></>}</div>
        <div className="grid place-items-center mb-2 mr-5">{dmk ? <ShowLegend data={dmk} legends={getSentimentLegend()} /> : <></>}</div>
      </div>
    </div>
  );
};

export default SentimentNarration;
