import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { FC } from "react";
import { TWEETS_SENTIMENT_DATA } from "../../api/helpers/DataQueryKeys";
import { getTweetsSentiment } from "../../api/SocialMediaServices";
import { convertToUTC } from "../../utils/api";
import { errorString, getSentimentLegend, noDataString, ShowLegend, showLoadingIcon, showText } from "../../utils/helper";
import { createSentimentChartData, getSentimentChartOption } from "../../utils/SentimentUtility";
import DoughnutChart from "../Charts/DoughnutChart/DoughnutChart";
import LiveChart from "../Charts/LiveChart/LiveChart";
import "./TweetSentiment.scss";
// import * as Sentry from "@sentry/react";
import { Colors } from "../Charts/Helpers/ChartUtility";

interface TweetSentimentProps {
  title: string;
  date?: Date;
}

const getPreviousDates = (last: number, dateFormat?: string): any => {
  return [...Array(last)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - (last - 1));
    d.setDate(d.getDate() + i);
    return format(d, dateFormat || "yyyy-MM-dd");
  });
};

const timeCharOption = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    scales: {
      y: {
        ticks: {
          stepSize: 50,
        },
      },
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      ticks: {
        color: Colors.white,
        stepSize: 50,
      },
      min: 0,
      max: 100,
      grid: {
        color: Colors.lightGray,
        drawTicks: false,
      },
      title: {
        display: true,
        text: "Percentage(%)",
        stepSize: 50,
      },
    },
    x: {
      ticks: {
        color: Colors.white,
      },
      grid: {
        color: "",
      },
    },
  },
};

const positive = "Positive";
const negative = "Negative";

const getDataSet = (data: any, date: string, label: string) => {
  let value = null;
  if (data) {
    const val = data.find((x: any) => x[date]);
    value = (val && val[date][label]) || 0;
  }
  return value;
};

const getListOfDataSet = (data: any, label: string) => {
  let dataSets = null;
  if (data) {
    const dateList = getPreviousDates(7);
    dataSets = dateList.map((date: string) => getDataSet(data, date, label));
  }
  return dataSets;
};

const getOneWeekChartData = (data: any) => {
  if (!data) return null;
  return {
    labels: getPreviousDates(7, "MMM dd"),
    datasets: [
      {
        label: positive,
        data: getListOfDataSet(data, positive),
        borderColor: Colors.darkPink,
        backgroundColor: Colors.darkPink,
        cubicInterpolationMode: "monotone" as any,
        pointRadius: 1,
        responsive: true,
      },
      {
        label: negative,
        data: getListOfDataSet(data, negative),
        borderColor: Colors.darkRed,
        backgroundColor: Colors.darkRed,
        cubicInterpolationMode: "monotone" as any,
        pointRadius: 1,
        responsive: true,
      },
    ],
  };
};

const TweetSentiment: FC<TweetSentimentProps> = (props) => {
  const currentDate = props.date ? new Date(props.date.getTime()) : new Date();
  const toDate = convertToUTC({ date: currentDate, setZeroSec: true });
  currentDate.setHours(currentDate.getHours() - 1);
  const fromDateWithHrs = convertToUTC({ date: currentDate, setZeroSec: true });
  const fromDate = convertToUTC({ date: currentDate, strFormat: "yyyy-MM-DD" });
  console.log("fromDate",currentDate)
  console.log("toDate",toDate)
  const { data: onedayData, isLoading, error } = useQuery([TWEETS_SENTIMENT_DATA, 1], () => getTweetsSentiment(fromDateWithHrs, toDate));
  
  currentDate.setHours(currentDate.getHours() + 1);
  currentDate.setDate(currentDate.getDate() - 6);
  const fromDate7 = convertToUTC({ date: currentDate, setZeroSec: true });
  const {
    data: oneWeekData,
    isLoading: oneWeekDataIsLoading,
    error: oneWeekDataError,
  } = useQuery([TWEETS_SENTIMENT_DATA, 7], () => getTweetsSentiment(fromDate7, toDate));

  if (isLoading || oneWeekDataIsLoading) {
    return showLoadingIcon();
  }

  if (error || oneWeekDataError) {
    // Sentry.captureException(error ? error : oneWeekDataError);
    return showText(errorString);
  }

  const oneDaySentiment: any | null = onedayData && onedayData.length > 0 ? (onedayData as any)[0][fromDate] : null;

  const oneDayChartData = oneDaySentiment ? createSentimentChartData(oneDaySentiment, "60%") : null;
  const chartOption = getSentimentChartOption();

  const oneWeekChartData = oneWeekData ? getOneWeekChartData(oneWeekData) : null;

  return (
    <div className="TweetSentiment" data-testid="TweetSentiment">
      <h1 className="text-chartTitle p-5"> {props.title} </h1>

      <div className="grid">
        <div className="grid grid-cols-10 justify-center">
          <div className="grid col-span-6 ml-10 mt-2 md:col-span-8 lg:col-span-6 justify-items-center">
            {oneDayChartData ? (
              <DoughnutChart chartData={oneDayChartData} options={chartOption} className={"grid w-1/3 sm:w-1/5 md:w-1/4 lg:w-2/4"}></DoughnutChart>
            ) : (
              showText(noDataString, "p-16")
            )}
          </div>
          {oneDayChartData ? (
            <div className="grid ">
              <ShowLegend showOrder="row" data={oneDaySentiment} legends={getSentimentLegend()}></ShowLegend>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="grid mt-5 mb-5 justify-items-center">
          {oneWeekChartData ? (
            <LiveChart
              chartData={oneWeekChartData}
              options={timeCharOption}
              className={"grid w-11/12"}
              applyGradient={true}
              height={"90%"}
            ></LiveChart>
          ) : (
            showText(noDataString, "p-20")
          )}
        </div>
      </div>
    </div>
  );
};

export default TweetSentiment;
