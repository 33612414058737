import { AccountInfo } from "@azure/msal-browser";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { value: AccountInfo | null; error: boolean } = {
    value: null,
    error: false,
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setUser: (state, action: PayloadAction<AccountInfo | null>) => {
        state.value = action.payload;
      },
      setError: (state, action: PayloadAction<boolean>) => {
        state.error = action.payload;
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const { setUser, setError } = userSlice.actions;
  
  export default userSlice.reducer;