import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";
import { TWEETS_TREND_DATA } from "../../api/helpers/DataQueryKeys";
import { getTweetTrend } from "../../api/SocialMediaServices";
import { convertToUTC } from "../../utils/api";
import {
  convertMinToMs,
  errorString,
  getTwitterTrendLabelColor,
  noDataString,
  showLoadingIcon,
  showText,
  ShowTwitterTrendLegend,
} from "../../utils/helper";
import LiveChart from "../Charts/LiveChart/LiveChart";
import "./TweetTrend.scss";
// import * as Sentry from "@sentry/react";
import { Colors } from "../Charts/Helpers/ChartUtility";

interface TweetTrendProps {
  title: string;
  date?: Date;
}

const getListOfValues = (data: any) => {
  return data.map((item: any) => {
    return getXYValue(item);
  });
};

const getXYValue = (item: any) => {
  const date = new Date(item.X);
  return {
    x: date.getTime(),
    y: item.Y,
  };
};

const updateChart = async (chart: any, minute?: number) => {
  if (!chart.options.allowRefresh) {
    chart.options.allowRefresh = true;
    return;
  }

  const currentDate = new Date();
  let to = convertToUTC({ date: currentDate, setZeroSec: true });
  currentDate.setMinutes(currentDate.getMinutes() - (minute || 10));
  let from = convertToUTC({ date: currentDate, setZeroSec: true });

  const lastTenMinData: any = await getTweetTrend(from, to);

  chart.data.datasets.forEach((chartData: any) => {
    const data: any = lastTenMinData[chartData.label];

    data.map((item: any) => {
      chartData.data.push(getXYValue(item));
    });
  });

  chart.update();
};

const options = {
  allowRefresh: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    responsive: true,
  },
  responsive: true,
  scales: {
    y: {
      ticks: {
        color: Colors.white,
        stepSize: 5,
      },
      suggestedMin: 0,
      suggestedMax: 5,
      grid: {
        color: Colors.lightGray,
        drawTicks: false,
      },
    },
    x: {
      ticks: {
        color: Colors.white,
      },
      grid: {
        color: "",
      },
      type: "realtime" as const,
      realtime: {
        duration: convertMinToMs(60),
        refresh: convertMinToMs(10), // 10min once
        delay: 800,
        onRefresh: async (chart: any) => {
          updateChart(chart);
        },
      },
    },
  },
};

const getChartData = (data: any) => {
  return {
    datasets: Object.entries(data).map((item) => {
      return {
        label: item[0],
        data: getListOfValues(item[1]),
        backgroundColor: getTwitterTrendLabelColor(item[0], true),
        borderColor: getTwitterTrendLabelColor(item[0], true),
        cubicInterpolationMode: "monotone" as any,
        pointRadius: 1,
      };
    }),
  };
};

const TweetTrend: FC<TweetTrendProps> = (props) => {
  const currentDate = props.date ? new Date(props.date.getTime()) : new Date();
  let to = convertToUTC({ date: currentDate, setZeroSec: true });
  currentDate.setHours(currentDate.getHours() - 1);
  let from = convertToUTC({ date: currentDate, setZeroSec: true });

  const { data, isLoading, error } = useQuery([TWEETS_TREND_DATA], () => getTweetTrend(from, to));
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    if (data) {
      const tmp = getChartData(data);
      setChartData(tmp);
    }
  }, [data]);

  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  return (
    <div className="TweetTrend" data-testid="TweetTrend">
      <div className="grid">
        <h1 className="text-chartTitle pl-5 mt-5">Tweet Trend</h1>
      </div>

      {chartData ? (
        <div className="grid grid-cols-11">
          <div className="col-span-9 ml-5">
            <LiveChart chartData={chartData} options={options} className={"grid w-11/12 ml-5 mb-5 pt-12"} height={"70%"} />
          </div>

          <ShowTwitterTrendLegend data={Object.keys(data as Object)}></ShowTwitterTrendLegend>
        </div>
      ) : (
        showText(noDataString)
      )}
    </div>
  );
};

export default TweetTrend;
