import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import { useLocation } from "react-router-dom";
import { AppMenus, PrintMediaBgImage } from "../common/helper/AppMenu.Config";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

interface ILayoutProps {
  children: React.ReactNode;
}

const getBgImage = (routePath: string) => {
  if (routePath === "/") return PrintMediaBgImage;

  return AppMenus.filter((menu) => menu.routingPath.toLowerCase() === routePath.toLowerCase())[0]?.bgImage;
};

export const Layout: React.FC<ILayoutProps> = (props) => {
  const location = useLocation();

  return (
    <>
      <AuthenticatedTemplate>
        <div className={`flex bg-cover  ${getBgImage(location.pathname)}`}>
          <Sidebar />
          <div className="flex flex-col h-screen w-screen overflow-x-hidden ">
            <Header />
            <main>{props.children}</main>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <main>{props.children}</main>
      </UnauthenticatedTemplate>
    </>
  );
};
