import React, { FC, useEffect, useState } from "react";
import "./Header.scss";
import { useLocation } from "react-router-dom";
import { AppMenus, PrintMediaTitle, PrintMediaTitleStyle } from "../../common/helper/AppMenu.Config";
import { LogoutIcon } from "../../common/icons/LogoutIcon";
import { logoutRequest } from "../../api/msal.service";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/dark.css";
import moment from "moment";
import { CalendarIcon } from "../../common/icons/CalendarIcon";

interface HeaderProps {}

const GetHeaderTitle = (style?: boolean) => {
  let location = useLocation();

  if (location.pathname === "/") return style ? PrintMediaTitleStyle : PrintMediaTitle;

  const menu = AppMenus.filter((menu) => menu.routingPath.toLowerCase() === location.pathname.toLowerCase())[0];
  return style ? menu?.titleClass : menu?.title;
};

const Header: FC<HeaderProps> = () => {
  const res = window.sessionStorage.getItem("datePicker");
  const d = res != null && res !== "" ? new Date(res) : new Date(Date.now());

  const [initDate] = useState<Date>(d);
  const [userDate, setUserDate] = useState<Date | null>(null);
  const [fp, setFP] = useState<Flatpickr | null>();

  useEffect(() => {
    if (userDate != null) {
      window.sessionStorage.setItem("datePicker", moment(userDate).format("yyyy-MM-DD"));
      window.location.reload();
    }
  }, [userDate]);

  return (
    <header className="header card mr-5 ml-5">
      <nav className="flex justify-between p-5 pl-5">
        <h1 className={`${GetHeaderTitle(true)}`}>{GetHeaderTitle()}</h1>
        <div className="flex">
          <Flatpickr
            className="date-input text-newsText"
            value={initDate}
            onChange={([date]) => setUserDate(date)}
            options={{ dateFormat: "D, d - M - Y" }}
            size={18}
            ref={(fp) => {
              setFP(fp);
            }}
          />
          <div
            className="cursor-pointer mt-1.5 mr-2"
            onClick={() => {
              if (fp) {
                fp.flatpickr.open();
              }
            }}
          >
            <CalendarIcon />
          </div>
          <div onClick={logoutRequest} className="m-2 mr-5 cursor-pointer">
            <LogoutIcon />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
