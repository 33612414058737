import { useRef, useEffect, useState, FC } from 'react';
import type { ChartData } from 'chart.js';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import './DoughnutChart.scss';
import { getChartWithGradientColor } from '../Helpers/ChartUtility';


ChartJS.register(...registerables, ChartDataLabels);

interface IDoughnutChartProps {
  chartData: any,
  options: any,
  className?: any,
}


const DoughnutChart: FC<IDoughnutChartProps> = (props: IDoughnutChartProps) => {

  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<'doughnut'>>({ datasets: [] });

  useEffect(() => {

    if (!chartRef.current) { return; }

    const data = getChartWithGradientColor(props.chartData, chartRef.current);
    setChartData(data);
  }, []);

  return (
    <div className={`${props.className} DoughnutChart w-2/3`} data-testid="DoughnutChart" >
      <Chart ref={chartRef} type='doughnut' data={chartData} options={props.options} />
    </div>
  )
};

export default DoughnutChart;
