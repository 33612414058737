export const CalendarIcon = () => {
  return (
    <svg id="calendar2-week-fill" xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 27 30">
      <path
        id="Path_10840"
        data-name="Path 10840"
        d="M25.375,4.063H3.625A1.812,1.812,0,0,0,1.813,5.875V25.813a1.813,1.813,0,0,0,1.812,1.813h21.75a1.813,1.813,0,0,0,1.813-1.812V5.875A1.812,1.812,0,0,0,25.375,4.063ZM3.625,2.25A3.625,3.625,0,0,0,0,5.875V25.813a3.625,3.625,0,0,0,3.625,3.625h21.75A3.625,3.625,0,0,0,29,25.813V5.875A3.625,3.625,0,0,0,25.375,2.25Z"
        transform="translate(0 -0.438)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_10841"
        data-name="Path 10841"
        d="M7.656,0a.906.906,0,0,1,.906.906v.906a.906.906,0,0,1-1.812,0V.906A.906.906,0,0,1,7.656,0ZM23.969,0a.906.906,0,0,1,.906.906v.906a.906.906,0,0,1-1.812,0V.906A.906.906,0,0,1,23.969,0Z"
        transform="translate(-1.313)"
        fill="#fff"
        fill-rule="evenodd"
      />
      <path
        id="Path_10842"
        data-name="Path 10842"
        d="M5.625,8.781a.906.906,0,0,1,.906-.906H24.656a.906.906,0,0,1,.906.906v1.813a.906.906,0,0,1-.906.906H6.531a.906.906,0,0,1-.906-.906Zm15.406,6.344a.906.906,0,0,1,.906-.906H23.75a.906.906,0,0,1,.906.906v1.813a.906.906,0,0,1-.906.906H21.938a.906.906,0,0,1-.906-.906Zm-5.437,0a.906.906,0,0,1,.906-.906h1.813a.906.906,0,0,1,.906.906v1.813a.906.906,0,0,1-.906.906H16.5a.906.906,0,0,1-.906-.906ZM6.531,20.563a.906.906,0,0,1,.906-.906H9.25a.906.906,0,0,1,.906.906v1.813a.906.906,0,0,1-.906.906H7.438a.906.906,0,0,1-.906-.906Zm5.438,0a.906.906,0,0,1,.906-.906h1.813a.906.906,0,0,1,.906.906v1.813a.906.906,0,0,1-.906.906H12.875a.906.906,0,0,1-.906-.906Z"
        transform="translate(-1.094 -1.531)"
        fill="#fff"
      />
    </svg>
  );
};
