import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";
import { LIVE_COVERAGE_DATA } from "../../api/helpers/DataQueryKeys";
import { getLiveCoverage } from "../../api/TvChannelsServices";
import { getValidDate } from "../../utils/api";
import { getTimeBarChartOptions, TimeBarChartOptionsProps } from "../../utils/BarChartUtility";
import { errorString, tvNoDataString, showLoadingIcon, showText, getNewsEventLabel, ShowLegend, newsSwapInterval } from "../../utils/helper";
import "./LiveCoverage.scss";
// import * as Sentry from "@sentry/react";
import BarChart from "../Charts/BarChart/BarChart";
import { IChannels, ILiveCoverage, ITiming } from "../../models/TVChannels";
import moment from "moment";
import { patternBackgroundColor, Colors } from "../Charts/Helpers/ChartUtility";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AnimatedText from "react-animated-text-content";
import { NewsIndicatorIcon } from "../../common/icons/NewsIndicator";

interface LiveCoverageProps {
  title: string;
  date?: Date;
}

//check and extract the time, which has show and end time.
const getData = (channels: Array<IChannels>, showTiming: ITiming, channelIndex: number, timingIndex: number) => {
  showTiming.startTime = moment(showTiming.startTime).format("yyyy-MM-DD HH:mm:ss");
  showTiming.endTime = moment(showTiming.endTime).format("yyyy-MM-DD HH:mm:ss");

  let returnValue = [];
  let timings = [];
  let breakTimes = [];
  //Move to current position of channel then add time to it
  for (let index = 0; index < channelIndex; index++) {
    timings.push(0);
    breakTimes.push(0);
  }

  // initial value shoud be start time and end time.
  if (timingIndex === 0) {
    timings.push([showTiming.startTime, showTiming.endTime]);
  } else {
    //get diff in milliseconds
    let diff = Math.abs(moment(showTiming.startTime).diff(moment(channels[channelIndex].eventTiming[timingIndex - 1].endTime)));
    //adding break time to the corresponding postion or channel
    breakTimes.push(diff);
    returnValue.push({
      label: "break",
      data: breakTimes,
      backgroundColor: patternBackgroundColor("diagonal-right-left", "rgba(0,0,0,0.05)", Colors.lightPink, 7),
      borderColor: Colors.lightPink,
      borderWidth: 1,
      barThickness: 8,
    });

    //calculating the show time
    diff = Math.abs(moment(showTiming.endTime).diff(moment(showTiming.startTime)));
    timings.push(diff);
  }
  //adding show time to the corresponding postion or channel
  returnValue.push({
    label: "show",
    data: timings,
    barThickness: 8,
    gradientLabels: ["show"],
    borderWidth: 1,
  });
  return returnValue;
};

// to get the overall data including show and break time.
const getDataList = (channels: Array<IChannels> | undefined) => {
  if (!channels) return null;
  let data: any = [];

  channels.forEach((channel: IChannels, channelIndex: number) => {
    return channel.eventTiming.map((showTime: ITiming, timingIndex: number) => {
      let result = getData(channels, showTime, channelIndex, timingIndex);
      result.forEach((res) => data.push(res));
    });
  });

  return data;
};

const getChartData = (liveCoverage: ILiveCoverage) => {
  if (!liveCoverage) return null;

  return {
    labels: getNewsEventLabel(liveCoverage.channels, true),
    datasets: getDataList(liveCoverage.channels),
  };
};

const LiveCoverage: FC<LiveCoverageProps> = (props) => {
  const [date] = useState(getValidDate(props.date));
  const { data: liveCoverages, isLoading, error } = useQuery([LIVE_COVERAGE_DATA], () => getLiveCoverage(date));

  const [length, setLength] = useState(0);
  const [currentIndex, setIndex] = useState(0);

  const updateChartIndex = (backWard?: boolean) => {
    const isUpdatable = backWard ? backWard && currentIndex > 0 : currentIndex < length - 1;

    if (isUpdatable) {
      setIndex(currentIndex + (backWard ? -1 : 1));
    } else {
      setIndex(0);
    }
  };

  useEffect(() => {
    if (length <= 1) return;
    const interval = setInterval(updateChartIndex, newsSwapInterval);

    return () => clearInterval(interval);
  }, [length, currentIndex]);

  useEffect(() => {
    if (!liveCoverages) return;
    setLength(liveCoverages.length);
  }, [length, liveCoverages]);

  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  const chartData = liveCoverages ? getChartData(liveCoverages[currentIndex]) : null;

  const timeBarChartProps: TimeBarChartOptionsProps = {
    xAxisTitle: { display: true, text: "Duration (mins)" },
    yAxisTitle: { display: true, text: "Channel" },
    dataLabels: { display: false },
    yTicksFont: { size: 10 },
    layout: { padding: { top: 0, right: 40, bottom: 0, left: 0 } },
    minTime: moment(liveCoverages ? liveCoverages[currentIndex].eventStartTime : new Date()).format("yyyy-MM-DD HH:mm:ss"),
    maxTime: moment(liveCoverages ? liveCoverages[currentIndex].eventEndTime : new Date()).format("yyyy-MM-DD HH:mm:ss"),
    indexAxis: "y",
    stepSize: 2,
  };

  const chartOptions = getTimeBarChartOptions(timeBarChartProps);

  return (
    <div className="liveCoverage" data-testid="LiveCoverage">
      <div className="flex justify-between">
        <h1 className="text-chartTitle pl-5 mt-5"> {props.title} </h1>
        <div className="text-subTitle pt-6 mr-5">
          <ShowLegend legends={["Event time", "Break time"]} showPercentage={false}></ShowLegend>
        </div>
      </div>

      {chartData && liveCoverages ? (
        <div className="grid grid-cols-12 mt-5">
          <div className="grid col-span-1">
            <div className="flex gap-2">
              <FaChevronLeft color={Colors.gray} size={"1.5rem"} className="ml-5 cursor-pointer" onClick={() => updateChartIndex(true)} />
              <div className="mt-1">
                <NewsIndicatorIcon color={Colors.paleViolet} />
              </div>
            </div>
          </div>
          <div className="grid col-span-10">
            <AnimatedText
              className="text-newsText animated-paragraph ml-2"
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                ease: "ease-in-out",
              }}
              animationType="throw"
              duration={1.5}
              tag="h1"
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              {liveCoverages[currentIndex].news}
            </AnimatedText>
          </div>

          <div className="grid col-span-1 ml-5 mr-5">
            <FaChevronRight color={Colors.gray} size={"1.5rem"} className="cursor-pointer" onClick={() => updateChartIndex()} />
          </div>
        </div>
      ) : (
        ""
      )}

      {chartData ? (
        <div className={`mt-4`}>
          <BarChart chartData={chartData} chartOptions={chartOptions} colorStartFrom={"left"} height={"128%"} />
        </div>
      ) : (
        showText(tvNoDataString)
      )}
    </div>
  );
};

export default LiveCoverage;
