import { TailSpin } from "react-loader-spinner";
import { TwitterTrendStatusIcon } from "../common/icons/TwitterTrendStatusIcon";
import { IChannels } from "../models/TVChannels";
import news7 from "../common/images/news7.png";
import polimer from "../common/images/polimer.png";
import thanthi from "../common/images/thanthi.png";
import pttv from "../common/images/pt.png";
import sun from "../common/images/sun.png";
import tamil from "../common/images/tamil.png";
import kalaingar from "../common/images/kalaingar.png";
import murasu from "../common/images/murasu.png";
import news18 from "../common/images/news18.png";
import raj from "../common/images/raj.png";
import sathiyam from "../common/images/sathiyam.png";
import { Colors } from "../components/Charts/Helpers/ChartUtility";
import uuid from "react-uuid";

export const errorString = "Something Went Wrong";
export const noDataString = "To be updated";
export const printMediaNoDataString = "News to be updated";
export const tvNoDataString = "Events to be updated";

export const CmEvent = "CM";
export const MinisterEvent = "Minister";

export const pageRoutingInterval = 60000 * 5;
export const newsSwapInterval = 10000;

export const WordCloudBlob = "wordcloud";

function getBgColor(label: string) {
  switch (label) {
    case "Positive":
      return "bgPositive";
    case "Negative":
    case "DMK":
      return "bgNegative";
    case "Neutral":
      return "bgNeutral";
    case "Gov":
    case "Government":
      return "bgGov";
    case "AIADMK":
    case "ADMK":
      return "bgAdmk";
    case "BJP":
      return "bgBjp";
    case "Others":
      return "bgOthers";
    case "Break time":
      return "bgBreak";
    case "Event time":
      return "bgEvent";
    default:
      return "bgOthers";
  }
}

function getLabel(label: string) {
  if (label === "Government") return "Gov";
  return label;
}

export const getSentimentLegend = () => {
  return ["Negative", "Neutral", "Positive"];
};

export const getSubjectSplitLegend = () => {
  return ["Government", "DMK", "AIADMK", "BJP", "Others","CM"];
};

export const getRuleSetLegend = () => {
  return ["CM", "Gov", "Opposition", "Others", "Party"];
};

export const getTwitterTrendLabelColor = (label: string, withOpacity?: boolean): string => {
  switch (label) {
    case "CM":
      return Colors.darkYellow;
    case "Gov":
    case "Government":
      return Colors.lightBlue;
    case "Opposition":
      return withOpacity ? "rgba(77, 201, 0, 0.3)" : Colors.green;
    case "Party":
      return withOpacity ? "rgba(255, 74, 74, 0.3)" : Colors.lightRed;
    case "Others":
      return withOpacity ? "rgba(187, 93, 255, 0.3)" : Colors.violet;
    default:
      return "";
  }
};

type ShowLegendProps = {
  legends: string[];
  data?: any;
  showPercentage?: boolean;
  showOrder?: string;
};

export const ShowLegend: React.FC<ShowLegendProps> = (props) => {
  const showPercentage = props.showPercentage === undefined ? true : props.showPercentage;
  const isVertical = props.showOrder === "row";

  return (
    <div className="grid mt-2">
      <div className={`${isVertical ? "grid " : "flex"} justify-center`}>
        {props.legends.map((item: any) => (
          <div key={uuid()} className={`${isVertical ? "pt-2 md:pt-2 lg:pt-5 justify-between" : "grid justify-evenly space-x-5 ml-8"} flex `}>
            <div className="flex">
              <div className={`statusIcon ${getBgColor(item)} mt-1.5`} />
              <span className="text-statusText pl-2 mt-1"> {getLabel(item)}</span>
            </div>
            <span className={`text-subTitle font-bold pl-2 ${isVertical ? "pl-5" : "justify-center"}`}>
              {showPercentage ? (props.data[item] || 0) + "%" : ""}{" "}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

type TwitterTrendLegendProps = {
  data?: any;
};

export const ShowTwitterTrendLegend: React.FC<TwitterTrendLegendProps> = (props) => {
  const data = props.data ? props.data : getRuleSetLegend();

  return (
    <div className="grid lg:mt-10 lg:ml-10">
      {data.map((item: any) => (
        <div key={uuid()}>
          <div className="flex">
            <div className="mt-3">
              <TwitterTrendStatusIcon color={getTwitterTrendLabelColor(item)} />
            </div>
            <div className="text-subTitle pl-5">{item} </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export const showLoadingIcon = () => {
  return (
    <div className="grid justify-evenly p-28">
      <TailSpin color={Colors.deepSkyBlue} height={50} width={50} />
    </div>
  );
};

export const showText = (text: string, className?: string) => {
  return <div className={`grid justify-evenly text-subTitle ${className ? className : "p-28"}`}>{text}</div>;
};

export const convertMinToMs = (minute: number) => {
  return minute * 60000;
};

export const removeNewLines = (str: string) => {
  return str.replace(/(\r\n|\n|\r)/gm, "");
};

export const getNewsEventLabel = (list: any, noSplit?: boolean) => {
  return list.map((item: IChannels) => {
    item.name = item.name.replace("_", " ").toUpperCase();
    let index = noSplit ? -1 : item.name.indexOf(" ");
    let name: string[] = [];
    if (index >= 0 && item.name !== "NEWS 7") {
      const first = item.name.substring(0, index);
      const second = item.name.substring(index + 1, item.name.length);
      if (first !== "MAALAI") {
        name.push(first);
      }
      if (second !== "NEWS") {
        name.push(second);
      }
      return name;
    } else {
      return item.name;
    }
  });
};

export const getNewsIcon = (channelName: string) => {
  switch (channelName) {
    case "NEWS 7":
      return { image: news7, width: 40, height: 20 };
    case "NEWS18":
      return { image: news18, width: 40, height: 20 };
    case "RAJ":
      return { image: raj, width: 40, height: 20 };
    case "POLIMER":
      return { image: polimer, width: 40, height: 20 };
    case "THANTHI":
      return { image: thanthi, width: 20, height: 20 };
    case "SATHIYAM":
      return { image: sathiyam, width: 20, height: 20 };
    case "PTTV":
      return { image: pttv, width: 40, height: 20 };
    case "SUN":
      return { image: sun, width: 30, height: 20 };
    case "KALAIGNAR":
    case "KALAINGAR":
      return { image: kalaingar, width: 30, height: 20 };
    case "MURASU":
      return { image: murasu, width: 20, height: 20 };
    case "NEWS":
      return { image: tamil, width: 40, height: 20 };
    default:
      return { image: thanthi, width: 20, height: 20 };
  }
};
