import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";
import { CM_EVENTS_SUMMARY_DATA } from "../../api/helpers/DataQueryKeys";
import { errorString, tvNoDataString, showLoadingIcon, showText, getNewsEventLabel } from "../../utils/helper";
import "./NewsEventSummary.scss";
// import * as Sentry from "@sentry/react";
import { getNewsEventSummary } from "../../api/TvChannelsServices";
import { format } from "date-fns";
import LineChart from "../Charts/LineChart/LineChart";
import { IChannels } from "../../models/TVChannels";
import { BarChartOptionsProps, getBarChartOptions } from "../../utils/BarChartUtility";
import { getValidDate } from "../../utils/api";
import { Colors } from "../Charts/Helpers/ChartUtility";

interface NewsEventSummaryProps {
  title: string;
  date?: Date;
  previousNoDays?: number;
  eventName: string;
}

const NewsEventSummary: FC<NewsEventSummaryProps> = (props) => {
  const from = new Date(new Date(getValidDate(props.date, "yyyy/MM/dd HH:mm:ss")));
  from.setDate(from.getDate() - (props.previousNoDays ? props.previousNoDays : 7));
  const [toDate, setToDate] = useState(format(new Date(getValidDate(props.date, "yyyy/MM/dd HH:mm:ss")), "yyyy-MM-dd"));
  const [fromDate, setFromDate] = useState(format(new Date(from), "yyyy-MM-dd"));

  const { data: newsEvent, isLoading, error } = useQuery([CM_EVENTS_SUMMARY_DATA], () => getNewsEventSummary(props.eventName, fromDate, toDate));

  useEffect(() => {
    const from = new Date(new Date(getValidDate(props.date, "yyyy/MM/dd HH:mm:ss")));
    from.setDate(from.getDate() - (props.previousNoDays ? props.previousNoDays : 7));

    setFromDate(format(new Date(from), "yyyy-MM-dd"));

    setToDate(format(new Date(getValidDate(props.date, "yyyy/MM/dd HH:mm:ss")), "yyyy-MM-dd"));
  }, [newsEvent]);

  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  const getChartData = (channels: Array<IChannels>) => {
    if (!channels || channels.length === 0) return null;

    return {
      labels: getNewsEventLabel(channels),
      datasets: [
        {
          label: "Duration",
          data: channels.map((item: IChannels) => Number(item.duration)),
          fill: false,
          gradientLabels: ["CmSummary"],
        },
      ],
    };
  };

  const chartData = newsEvent ? getChartData(newsEvent.channels) : null;

  const dataLabels = {
    backgroundColor: Colors.white,
    display: true,
    borderRadius: 5,
    color: Colors.lightWhite,
    font: {
      weight: "bold",
    },
    formatter: (value: number) => {
      return value === 0 ? "" : value;
    },
    anchor: "center",
    align: "top",
    offset: -10,
  };

  const barChartProps: BarChartOptionsProps = {
    xAxisTitle: { display: true, text: "Channel" },
    yAxisTitle: { display: true, text: "Duration (mins)" },
    dataLabels: dataLabels,
    xTicksFont: { size: 10 },
    yTicksFont: { size: 10 },
    layout: { padding: { top: 0, right: 20, bottom: 0, left: 0 } },
    stepSize: Math.round(chartData && chartData.datasets[0].data.length > 0 ? Math.max(...chartData.datasets[0].data) / 3 : 1),
  };
  const chartOptions = getBarChartOptions(barChartProps);

  return (
    <div className="p-5" data-testid="NewsEventSummary">
      <h1 className="text-chartTitle"> {props.title} </h1>

      {chartData ? (
        <div className="mt-10 pt-16">
          <LineChart chartData={chartData} chartOptions={chartOptions} colorStartFrom={"left"} />
        </div>
      ) : (
        showText(tvNoDataString)
      )}
    </div>
  );
};

export default NewsEventSummary;
