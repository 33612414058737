import { URL } from "../config/URL.Config";
import { INewsEventsItems, ILiveCoverages, INewsEventsSummary } from "../models/TVChannels";
import AxiosServices from "./AxiosServices";

const instance = new AxiosServices().getServiceInstance();

export const getNewsEvents = async (eventName: string, date: string) => {
  let newsEventsData: INewsEventsItems;
  let props  =  {'event_name':eventName,'date':date}
  const res = await instance.post(`${URL.tvChannels.newsEvent}/`,props);
  newsEventsData = Object.keys(res.data).length > 0 ? res.data : null;
  return newsEventsData;
};

export const getNewsEventSummary = async (eventName: string, fromDate: string, toDate: string) => {
  let newsEventsData: INewsEventsSummary;
  let props  =  {'event_name':eventName,'fromDate':fromDate,'toDate':toDate}
  const res = await instance.post(`${URL.tvChannels.newsEventSummary}/`,props);
  newsEventsData = Object.keys(res.data).length > 0 ? res.data : null;
  return newsEventsData;
};

export const getLiveCoverage = async (date: string) => {
  let liveCoverageData: ILiveCoverages;
  const res = await instance.get(`${URL.tvChannels.liveCoverage}/${date}`);
  liveCoverageData = Object.keys(res.data).length > 0 ? res.data : null;
  return liveCoverageData;
};
