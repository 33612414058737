type NewsIndicatorIconProps = {
  color: string;
};

export const NewsIndicatorIcon: React.FC<NewsIndicatorIconProps> = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16">
      <circle id="Ellipse_35" data-name="Ellipse 35" cx="8" cy="8" r="8" fill={color || "white"} />
    </svg>
  );
};
