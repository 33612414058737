import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./styles/App.scss";
import { Layout } from "./pages/Layout";
import AppRoutes from "./routes";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./api/msal.service";
import { Provider } from "react-redux";
import { store } from "./store/store";
// import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY,
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const queryClient = new QueryClient();

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <AppRoutes />
          </Layout>
        </QueryClientProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
