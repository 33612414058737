import { ISentimentItem } from "../models/Common";
import { getSentimentLegend } from "./helper";

const getValues = (labels: string[], data: any) => {
  return labels.map((item) => data[item] || 0);
};

export const createSentimentChartData = (data: ISentimentItem, cutout: string): any => {
  const labels = getSentimentLegend();
  return {
    labels: labels,
    datasets: [
      {
        data: getValues(labels, data),
        borderWidth: 1,
        cutout: cutout,
        responsive: true,
        maintainAspectRatio: false,
        gradientLabels: labels,
      },
    ],
  };
};

export const getSentimentChartOption = () => {
  return {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };
};
