export const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 27.006 27">
      <path
        id="logout"
        d="M4.5,7.5a3.009,3.009,0,0,1,3-3h12v3H7.5v21h12v3H7.5a3.009,3.009,0,0,1-3-3Zm21.264,9-3.8-3.8,2.121-2.121L31.506,18l-7.425,7.425L21.96,23.3l3.8-3.8H15.885v-3Z"
        transform="translate(-4.5 -4.5)"
        fill="#fff"
        fill-rule="evenodd"
      />
    </svg>
  );
};
