import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrintMediaTitle, TvChannelsRoutePath } from "../../common/helper/AppMenu.Config";
import NewsArticles from "../../components/NewsArticles/NewsArticles";
import SentimentNarration from "../../components/SentimentNarration/SentimentNarration";
import SubjectSplit from "../../components/SubjectSplit/SubjectSplit";
import TopNews from "../../components/TopNews/TopNews";
import { getUserSelectedDate } from "../../utils/api";
import { pageRoutingInterval } from "../../utils/helper";

export const PrintMediaDasboard = () => {
  const [userDate] = useState(getUserSelectedDate());
  const navigation = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigation(TvChannelsRoutePath);
    }, pageRoutingInterval);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <AuthenticatedTemplate>
        <div className="flex flex-col">
          <div className="grid pt-2 sm:grid-cols-0 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
            <div className="card ml-5 mr-5">
              <TopNews media={PrintMediaTitle} title="Top 5 News" count={5} hasSentiment={true} date={userDate}></TopNews>
            </div>

            <div className="card grid mr-5 ml-5 mt-5 lg:ml-0 lg:mt-0 pt-50 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5">
              <SentimentNarration date={userDate}></SentimentNarration>
            </div>

            <div className="grid pt-2 lg:grid-cols-3 lg:col-span-2 xl:grid-cols-3 xl:col-span-2 2xl:grid-cols-3 2xl:col-span-2 md:grid-cols-1 mt-4">
              <div className="card ml-5 mr-5 lg:col-span-1 md:mr-5 sm:mr-5">
                <SubjectSplit title="Subject Split" date={userDate}></SubjectSplit>
              </div>
              <div className="card ml-5 mt-5 lg:col-span-2 mr-5 lg:ml-0 lg:mt-0 md:mt-5 md:ml-5 sm:mt-5 sm:ml-5">
                <div>
                  <NewsArticles date={userDate}></NewsArticles>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <></>
      </UnauthenticatedTemplate>
    </>
  );
};
