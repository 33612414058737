import React, { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { BsXLg } from "react-icons/bs";
import { BurgerMenuIcon } from "../../common/icons/BurgerMenuIcon";
import { AppMenus } from "../../common/helper/AppMenu.Config";
import uuid from "react-uuid";

interface ISidebarProps {
  //defaultActive: number
}

const Sidebar: FC<ISidebarProps> = (props) => {
  const [open] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();

  return (
    <div className="glassmorphismBlack rounded-r-lg  2xl:inline-grid xl:inline-grid lg:inline-grid ">
      <div className={`duration-500 ${open ? "w-72" : "w-20"} relative`}>
        <div className="inline-flex mt-5">
          {" "}
          {/* onClick={() => setOpen(!open)} */}
          {open ? (
            <BsXLg className="text-white text-4xl absolute cursor-pointer ml-6" />
          ) : (
            // <BsList className="text-white text-4xl  absolute cursor-pointer ml-6" />
            <div className="pl-5">
              <BurgerMenuIcon />
            </div>
          )}
        </div>

        <ul className="duration-500 sidebar cursor-pointer">
          {AppMenus.map((menu, index) => (
            <li
              key={uuid()}
              className="text-gray-300 text-sm flex items-center p-5 mt-2 justify-center"
              id={location.pathname.toLowerCase() === menu.routingPath.toLowerCase() || (location.pathname === "/" && index === 0) ? "active" : ""}
              onClick={() => {
                navigation(menu.routingPath);
              }}
            >
              <Link to={menu.routingPath} className="inline-flex space-x-5">
                <span className="text-2xl block float-left">{menu.icon} </span>
                <span className={`text-base font-medium flex-1 duration-500 ${!open && "hidden"}`}>{menu.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
