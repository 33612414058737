export const DMK_SENTIMENT_DATA = "DMK";
export const GOV_SENTIMENT_DATA = "Government";
export const SUBJECT_SPLIT_DATA = "SubjectSplit";
export const NEWS_ARTICLES_DATA = "NewsArticles";
export const TWEETS_SENTIMENT_DATA = "TweetsSentiment";
export const TWEETS_WORD_CLOUD_DATA = "TweetsWordCloud";
export const TWEETS_TREND_DATA = "TweetTrend";
export const LIVE_COVERAGE_DATA = "LiveCoverage";
export const PRINT_TOP_NEWS = "PrintTopNews";
export const TV_TOP_NEWS = "TvTopNews";
export const SOCIAL_TOP_NEWS = "SocialTopNews";
export const CM_EVENTS_DATA = "CmEvents";
export const MINISTER_EVENTS_DATA = "MinisterEvents";
export const CM_EVENTS_SUMMARY_DATA = "CmEventSummary";
export const SAS_TOKEN_DATA = "SasToken";
