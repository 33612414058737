import React, { FC } from 'react';
import './Login.scss';
import SignIn from '../../components/SignIn/SignIn';
interface LoginProps {}

const Login: FC<LoginProps> = () => (
  <div className="Login" data-testid="Login">
    <SignIn></SignIn>
  </div>
);

export default Login;
