import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FC, useState, useEffect } from "react";
import { CM_EVENTS_DATA, MINISTER_EVENTS_DATA } from "../../api/helpers/DataQueryKeys";
import { getValidDate } from "../../utils/api";
import {
  errorString,
  tvNoDataString,
  showLoadingIcon,
  showText,
  getNewsEventLabel,
  CmEvent,
  MinisterEvent,
  getNewsIcon,
  newsSwapInterval,
} from "../../utils/helper";
import "./NewsEvents.scss";
// import * as Sentry from "@sentry/react";
import { getNewsEvents } from "../../api/TvChannelsServices";
import BarChart from "../Charts/BarChart/BarChart";
import { BarChartOptionsProps, getBarChartOptions } from "../../utils/BarChartUtility";
import { IChannels, INewsEventsItem } from "../../models/TVChannels";
import AnimatedText from "react-animated-text-content";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { NewsIndicatorIcon } from "../../common/icons/NewsIndicator";
import { Colors } from "../Charts/Helpers/ChartUtility";
interface NewsEventsProps {
  title: string;
  date?: Date;
  eventName: string;
}

const NewsEvents: FC<NewsEventsProps> = (props) => {
  const [date] = useState(getValidDate(props.date));
  const isCMEvent: boolean = props.eventName === CmEvent;
  const isMinisterEvent: boolean = props.eventName === MinisterEvent;

  const [newsEvent, setData] = useState<INewsEventsItem>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [chartData, setChartData] = useState<any>();
  const query = useQueryClient();
  const [length, setLength] = useState(0);
  const [currentIndex, setIndex] = useState(0);

  const getChartData = (data: any) => {
    if (!data) return null;
    return {
      labels: data ? getNewsEventLabel(data.channels) : "",
      datasets: [
        {
          data: data ? data.channels.map((item: IChannels) => Number(item.duration)) : [0],
          barThickness: 22,
          label: "Duration",
          gradientLabels: [props.eventName],
          minBarLength: "10",
        },
      ],
    };
  };

  const {
    data: cmNewsEvent,
    isLoading: cmDataIsLoading,
    error: cmDataErr,
  } = useQuery([CM_EVENTS_DATA, isCMEvent], () => getNewsEvents(props.eventName, date), { enabled: isCMEvent });
  const {
    data: minNewsEvent,
    isLoading: minDataIsLoading,
    error: minDataErr,
  } = useQuery([MINISTER_EVENTS_DATA, isMinisterEvent], () => getNewsEvents(props.eventName, date), { enabled: isMinisterEvent });

  useEffect(() => {
    if (isCMEvent) {
      setLoading(cmDataIsLoading);
      setError(cmDataErr);
    } else if (isMinisterEvent) {
      setLoading(minDataIsLoading);
      setError(minDataErr);
    }

    if (cmNewsEvent) {
      setData(cmNewsEvent[currentIndex]);
    } else if (minNewsEvent) {
      setData(minNewsEvent[currentIndex]);
    }

    setChartData(getChartData(newsEvent));

    return () => {
      query.invalidateQueries([CM_EVENTS_DATA, MINISTER_EVENTS_DATA]);
      setLoading(false);
      setError([]);
    };
  }, [currentIndex, cmDataIsLoading, minDataIsLoading, cmDataErr, minDataErr, newsEvent, error]);

  const updateChartIndex = (backWard?: boolean) => {
    const isUpdateAllow = backWard ? backWard && currentIndex > 0 : currentIndex < length - 1;

    if (isUpdateAllow) {
      setIndex(currentIndex + (backWard ? -1 : 1));
    } else {
      setIndex(0);
    }
  };

  useEffect(() => {
    if (length <= 1) return;

    const interval = setInterval(updateChartIndex, newsSwapInterval);

    return () => clearInterval(interval);
  }, [length, currentIndex]);

  useEffect(() => {
    if (isCMEvent && cmNewsEvent) {
      setLength(cmNewsEvent.length);
    } else if (isMinisterEvent && minNewsEvent) {
      setLength(minNewsEvent.length);
    }
  }, [length, cmNewsEvent, minNewsEvent]);

  if (isLoading) {
    return showLoadingIcon();
  }

  if (error) {
    // Sentry.captureException(error);
    return showText(errorString);
  }

  const plugin = [
    {
      afterDatasetsDraw: (chart: any) => {
        const ctx = chart.ctx;
        const xAxis = chart.scales["x"];
        const yAxis = chart.scales["y"];

        xAxis.ticks.forEach((value: any, index: number) => {
          const x = xAxis.getPixelForTick(index);
          const image = new Image(0.1, 2);
          const label = value.label.length > 2 ? value.label : value.label[0];
          const img = getNewsIcon(label);
          image.src = img.image;
          ctx.drawImage(image, x - img.width / 2, yAxis.bottom + 10, img.width, img.height);
        });
      },
    },
  ];

  const barChartProps: BarChartOptionsProps = {
    xAxisTitle: { display: true, text: "" },
    yAxisTitle: { display: true, text: "Duration (mins)" },
    xTicksFont: { size: 10 },
    dataLabelColor: Colors.black,
    xTicksDisplay: false,
    stepSize: Math.round(chartData ? Math.max(...chartData.datasets[0].data) / 3 : 5),
  };

  const chartOptions = getBarChartOptions(barChartProps);

  return (
    <div className={`newsEvent`} data-testid="NewsEvents">
      <h1 className="text-chartTitle pl-5 mt-5"> {props.title} </h1>
      {newsEvent ? (
        <div className="grid grid-cols-12 mt-10">
          <div className="grid col-span-1">
            <div className="flex gap-2">
              <FaChevronLeft color={Colors.gray} size={"1.5rem"} className="cursor-pointer ml-5" onClick={() => updateChartIndex(true)} />
              <div className="mt-1">
                <NewsIndicatorIcon color={Colors.paleViolet} />
              </div>
            </div>
          </div>
          <div className="grid col-span-10">
            <AnimatedText
              className="text-newsText animated-paragraph ml-2"
              type="words"
              animation={{
                x: "200px",
                y: "-20px",
                ease: "ease-in-out",
              }}
              animationType="throw"
              duration={1.5}
              tag="h1"
              includeWhiteSpaces
              threshold={0.1}
              rootMargin="20%"
            >
              {newsEvent?.news}
            </AnimatedText>
          </div>

          <div className="grid col-span-1 ml-5 mr-5">
            <FaChevronRight color={Colors.gray} size={"1.5rem"} className="cursor-pointer" onClick={() => updateChartIndex()} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        {newsEvent ? (
          <div className="mt-10 pb-3">
            <BarChart chartData={chartData} chartOptions={chartOptions} plugin={plugin} />
          </div>
        ) : (
          showText(tvNoDataString)
        )}
      </div>
    </div>
  );
};

export default NewsEvents;
