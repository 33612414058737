import axios, { AxiosInstance } from "axios";
import { store } from "../store/store";
import { setUser } from "../store/user.slice";
import * as authStorage from "./auth.service";
import { loginRequest, logoutRequest, msalInstance } from "./msal.service";

export default class AxiosServices {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create();
    this.init();
  }

  init() {
    this.registerRequestInterceptor();
    this.registerResponseInterceptor();
  }

  getServiceInstance() {
    return this.axiosInstance;
  }

  registerRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      (config: any) => {
        const tokenStr = "Bearer " + authStorage.authToken();
        if (tokenStr) {
          config.headers["Authorization"] = tokenStr;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
  }

  registerResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      async (error: { config: any; response: { status: number } }) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          try {
            const silentRequest = {
              scopes: loginRequest.scopes,
              account: msalInstance.getAllAccounts()[0] ?? undefined,
            };
            msalInstance
              .acquireTokenSilent(silentRequest)
              .then((authResponse) => {
                if (authResponse) {
                  authStorage.setAuthDetails(authResponse.accessToken, authResponse.idToken);
                  store.dispatch(setUser(authResponse.account));
                }
              })
              .catch((error) => {
                console.log("error", error);
                logoutRequest();
              });
          } catch (e) {
            logoutRequest();
          }
        }
        return Promise.reject(error);
      }
    );
  }
}
