const printMedia = "/PrintMedia";
const socialMedia = "/SocialMedia";
const tvChannels = "/VisualMedia";
const token = "/Token";

export const URL = {
  printMedia: {
    topNews: process.env.REACT_APP_BASEURL + printMedia + "/TopNews",
    sentimentNarration: process.env.REACT_APP_BASEURL + printMedia + "/SentimentNarration",
    newsArticles: process.env.REACT_APP_BASEURL + printMedia + "/NewsArticles",
    subjectSplit: process.env.REACT_APP_BASEURL + printMedia + "/SubjectSplit",
  },

  tvChannels: {
    newsEvent: process.env.REACT_APP_BASEURL + tvChannels + "/NewsEvent",
    newsEventSummary: process.env.REACT_APP_BASEURL + tvChannels + "/NewsEventSummary",
    liveCoverage: process.env.REACT_APP_BASEURL + tvChannels + "/LiveCoverage",
  },

  socialMedia: {
    topNews: process.env.REACT_APP_BASEURL + socialMedia + "/TopTweets",
    tweetsSentiment: process.env.REACT_APP_BASEURL + socialMedia + "/TweetsSentiment",
    tweetsWordCloud: process.env.REACT_APP_BASEURL + socialMedia + "/TweetsWordCloud",
    tweetsTrend: process.env.REACT_APP_BASEURL + socialMedia + "/TweetsTimeSeries",
  },
  token: {
    sasToken: process.env.REACT_APP_BASEURL + token + "/GetSasToken",
  },
};
